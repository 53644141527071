
export interface playerCount {
    player_i: number,
    count: number
}

export interface pairCount {
    count: number,
    last_num: number,
}

export interface changeHistory {
    battleCnt: number,
    playerCnt: number,
    displayPlayers: number[]
}

// 履歴
export interface resultData {
    playerCounts: playerCount[],
    matchCounts: number[][],
    playedPairs: { [key: string]: pairCount },
    // pairing: number[] TODO　pairingsは最終的にはこちらで定義
}
export class CombData {
    //【入力情報】
    playerCnt: number; // 参加人数
    battleCnt: number; // 試合回数

    //【計算情報】
    pairings: Set<number[]> = new Set(); // 全体のペアリングを格納する配列 [[1,2,3,4],[5,6,7,8],[9,1,5,2],[3,6,9,4]]
    pairing: number[] = []; // [1,2,3,4]

    //【計算結果履歴】
    results: resultData[] = [];
    matchCounts: number[][]; // 試合毎のマトリクスカウント
    playCounts: playerCount[];
    playedPairs: { [key: string]: pairCount } = {} // ['001-002' => {count: 1, last_num: 1},'003-014' => 2]

    //【変更履歴　人数追加・除外】
    changeHistory: changeHistory[];

    constructor(playerCnt: number, battleCnt: number) {
        this.playerCnt = playerCnt;
        this.battleCnt = battleCnt;
        this.playCounts = Array.from({ length: playerCnt }, (_, i: number): playerCount => { return { player_i: i, count: 0 }; });
        this.matchCounts = Array.from({ length: playerCnt }, (): number[] => Array(playerCnt).fill(0));

        this.changeHistory = [{ playerCnt: playerCnt, battleCnt: 0, displayPlayers: [] }]
    }
}
