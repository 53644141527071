
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import '../css/App.css';
import { CombData } from "./CombData";
import Button from './component/Button';

// 前画面から呼び出し
interface State {
	combData: CombData,
}

function List() {
	const location = useLocation();
	const navigate = useNavigate();
	const { combData } = location.state as State ?? [];

	const [count, countState] = useState<number>(() => {
		return parseInt(localStorage.getItem('count') || '0');
	}); // 対戦回数

	// 選んだ人を見やすくする
	const [selectPlayer, setSelectPlayer] = useState<number | null>(null);
	// 【組み合わせ】表示・非表示
	const [activeLine, setActiveLine] = useState<number[]>([]);
	const [active, setActive] = useState(true);
	// 現在の試合の組み合わせ　表示/非表示
	const [isNowMemberShow, setIsNowMemberShow] = useState(false);
	function handleClick(index: number) {
		setActiveLine((items) => {
			if (items.includes(index)) {
				return items.filter((i) => i !== index);// 既に存在する場合は削除
			} else {
				return [...items, index];// 存在しない場合は追加
			}
		});
	}

	// countが変更された時
	useEffect(() => {
		localStorage.setItem('count', count.toString());
		const element = document.querySelector('#root > div > div > table > tbody > tr:nth-child(' + count + ')');
		if (element && active) {
			element.scrollIntoView({ behavior: 'smooth', block: 'center' });
		}
	}, [count]);

	// 「戻る」ボタンをクリック
	const backBtnClick = () => {
		if (combData.changeHistory.length > 1 && !window.confirm('戻る場合は設定が初期化されます。よろしいですか？')) {
			// 追加・除外した　かつ　警告を「いいえ」にした場合
		} else {
			localStorage.clear();
			navigate("/", { replace: true, state: null });
		}
	};

	// 「次の試合から変更」ボタンをクリック
	const changeBtnClick = () => {
		navigate("/", {
			state: {
				nowBattleCnt: count + 1,
				combData: combData,
			}
		});
	};

	function sortByPairs(_gameMembers: number[]) {
		if (_gameMembers.length !== 4) {
			return _gameMembers; // ありえないが念の為
		}

		var firstPair: [number, number] = [_gameMembers[0], _gameMembers[1]]; // 1, 2番目の要素
		var secondPair: [number, number] = [_gameMembers[2], _gameMembers[3]]; // 3, 4番目の要素

		firstPair = firstPair.sort((a, b) => {
			return a - b;
		});

		secondPair = secondPair.sort((a, b) => {
			return a - b;
		});

		return [...firstPair, ...secondPair];
	}


	function playerSort(players: number[]) {
		players = players.sort((a, b) => { return a - b; });
		players = players.map((num) => { return num + 1; });
		return players;
	}

	const nowMembers: number[] = sortByPairs((Array.from(combData.pairings) ?? [])[count] ?? []);
	return (
		<>
			<header className="App-header">
				<h1><span className='font-bold'>ダブルス組み合わせ一覧</span></h1>
			</header>
			<div className='p-2 lg:p-6'>
				<div className='flex justify-between'>
					<Button className="lg:text-3xl lg:p-3 p-1.5 px-3 bg-neutral-600 text-white rounded-md text-sm font-bold" onClick={backBtnClick}>戻る</Button>

					<Button className=" bg-green-700 text-white" onClick={changeBtnClick}>次の試合から変更</Button>
				</div>
				{combData?.playCounts?.length > 0
					?
					(<>

						{/* 詳細情報（デバッグ） */}
						<Button onClick={() => { setActive(!active); }} className='mt-2 bg-gray-200 !text-gray-700'>詳細情報</Button>
						{/* <h2>【現在の設定】</h2>
							<div className={`overflow-x-auto`}>

						</div> */}
						<div className={`${active ? 'hidden' : ''} overflow-x-auto`}>
							<h2>【個人回数】</h2>
							<div className='grid grid-cols-10'>

								{combData.results[count].playerCounts.map((_playerCount) => {
									return (
										<div key={_playerCount.player_i} className='flex flex-col border'>
											<span className='text-center bg-green-700 bg-opacity-80 text-white text-xs font-semibold'>{_playerCount.player_i + 1}</span>
											<span className={`mx-1 text-end text-sm ${activeLine.includes(_playerCount.player_i) ? 'bg-amber-100' : ''}`}
												onClick={() => { handleClick(_playerCount.player_i) }}
											>{_playerCount.count}</span>
										</div>

									)
								})}
							</div>
							<h2>【組み合わせ】</h2>
							<div className={`overflow-x-auto`}>
								<table border={1}>
									<thead>
										<tr>
											<th>試合</th>
											<th>人数</th>
											<th>除外</th>
										</tr>
									</thead>
									<tbody>
										{combData.changeHistory.map((changeHistory, i) => {
											return (
												<>
													<tr>
														<td><span className='lg:text-4xl lg:mx-2 text-lg mx-1'>{changeHistory.battleCnt === 0 ? '初回' : changeHistory.battleCnt}</span></td>
														<td><span className='text-lg lg:text-4xl lg:mx-2 mx-1'>{changeHistory.playerCnt}</span></td>
														<td><div>{`[${playerSort(changeHistory.displayPlayers).join(',')}]`}</div></td>
													</tr>
												</>
											)
										})}
									</tbody>
								</table>
							</div>

							<h2>【組み合わせ】</h2><div className={`overflow-x-auto`}>{combData.results[count].matchCounts.map((list, i) => { return (<div key={`${i}`} className={`whitespace-nowrap flex justify-items-center items-center h-4 ${activeLine.includes(i) ? 'bg-green-200 text-black' : ''}`} onClick={() => handleClick(i)}>{list.map((player, player_i) => { return (<span key={`${player_i}`} className={`inline-block h-4 w-4 text-center text-xs ${activeLine.includes(player_i) ? 'bg-amber-100 text-black' : ''}`}>{i === player_i ? '×' : player}</span>) })}</div>) })}</div>
						</div>

						<span className={`my-4 flex justify-center ${isNowMemberShow ? '' : 'invisible'}`} >
							<label htmlFor={`${count}`}>
								{nowMembers.map((player, player_i) => {
									const playerClass: string = 'inline-block w-12 lg:w-24 text-center';
									return (
										<span key={`${player_i}`}>
											{(player_i === 2) && <span className={`${playerClass} 'text-black'}`}>VS</span>}
											<button
												className={`${playerClass} ${'bg-gray-50 text-black font-bold'} mx-0.5 px-1 border border-gray-400 rounded-md`}
												onClick={() => { setSelectPlayer(selectPlayer === player ? null : player) }}
											>{player + 1}</button>
										</span>
									)
								})}
							</label>
						</span>

						<div className='my-4 flex justify-between'>
							<Button onClick={() => { if (count !== 0) { countState(count - 1); } }}>
								前の試合
							</Button>
							<span className="text-sm lg:text-3xl" onClick={() => { setIsNowMemberShow(!isNowMemberShow); }}>
								<div className='text-center'>試合<span className='text-lg lg:text-4xl mx-1'>{count + 1}</span>回目</div>
							</span>
							<Button onClick={() => { if (count !== combData.results?.length - 1) { countState(count + 1); } }}>
								次の試合
							</Button>
						</div>

						<div className='lg:text-4xl my-2 py-2 h-[calc(100vh-250px)] overflow-scroll border-gray-300 border-2'>
							<table className='w-full'>
								<tbody>{/* 対戦一覧 */}
									{(Array.from(combData.pairings) ?? []).map((_gameMembers, game_i) => { // membersは対戦相手
										const gameMembers = sortByPairs(_gameMembers); // ソートする
										return ( // 横並びで表示する
											<tr key={`${game_i}`}>
												{/* 終わっている組み合わせは背景色を変更する */}
												<td className={`lg:mt-2 flex justify-center`}>
													<label htmlFor={`${game_i}`} className={`mx-1 lg:mx-12 w-6 text-end ${count === game_i ? 'text-black' : 'text-gray-400 opacity-50'}`} >{`${game_i + 1}`}</label>
													<input type="radio" id={`${game_i}`} className={`mx-1`} checked={count === game_i} onChange={() => { countState(game_i) }} />
													<label htmlFor={`${game_i}`}>
														{gameMembers.map((player, player_i) => {
															const playerClass: string = 'inline-block w-12 lg:w-24 text-center';
															return (
																<span key={`${player_i}`}>
																	{(player_i === 2) && <span className={`${playerClass} ${count === game_i ? 'text-black' : 'text-gray-400 opacity-50'}`}>VS</span>}
																	<button
																		className={`${playerClass} ${selectPlayer === player ? 'bg-green-700 text-white font-bold' : (count === game_i ? 'bg-yellow-50 text-black font-bold' : 'bg-gray-50')} mx-0.5 px-1 border border-gray-400 rounded-md`}
																		onClick={() => { setSelectPlayer(selectPlayer === player ? null : player) }}
																	>{player + 1}</button>
																</span>
															)
														})}
													</label>
												</td>
											</tr>
										)
									})}
								</tbody>
							</table>
						</div>
					</>)
					:
					(<div>何も設定されていません。</div>)
				}
			</div >
		</>
	);
}

export default List;